import { useQuery } from "@tanstack/vue-query";
import { campaignQueries } from "./queries";

export const useCampaign = (campaignId: MaybeRefOrGetter<number>) => {
  const { data, isPending } = useQuery({
    ...campaignQueries.list(),
  });

  const campaign = computed(() => {
    return data.value?.find(
      (campaign) => campaign.campaignId === toValue(campaignId),
    );
  });

  return {
    isPending,
    campaign,
  };
};

export const useCampaigns = () => {
  return useQuery({
    ...campaignQueries.list(),
  });
};
